import "./App.css";

import {  createBrowserRouter, RouterProvider,} from "react-router-dom";
import TermsAndConditions from "./Pages/App/Pstet/TermsAndConditions";
import ErrorPage from "./Pages/ErrorPage";
import HomePage from "./Components/HomePage";
import PrivacyPolicy from "./Pages/App/Pstet/PrivacyPolicy";
import  SondhPrivacyPolicy  from "./Pages/Website/SondhWebCreators/PrivacyPolicy";
import  SondhTermsAndConditions from "./Pages/Website/SondhWebCreators/TermsAndConditions";
function App() {
  

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/App/pstet/termsandconditions",
      element:  <TermsAndConditions/>,
      
    },
    {
      path: "/App/pstet/privacypolicy",
      element:  <PrivacyPolicy/>,
      
    },
    {
      path: "/privacypolicy",
      element:  <SondhPrivacyPolicy/>,
      
    },
    {
      path: "/termsandconditions",
      element:  <SondhTermsAndConditions/>,
      
    },

  ]);
  return (
   
    <RouterProvider router={router} />
  );
}

export default App;

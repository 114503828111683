import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className="home-container" id="Home">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Sondh Web Creators. Website and Apps Creating Company.
          </h1>
          <p className="primary-text">
            Sondh Web Creators one name for all IT problems solution.
          </p>
          <a href="https://www.google.com/search?q=sondh+web+creators&sxsrf=AB5stBgJ7YojIVWu9is1E62Cf8MGQRxErg%3A1688993918423&ei=fgCsZO6xGYz4-QaZv4PoDQ&ved=0ahUKEwju6KPUmISAAxUMfN4KHZnfAN0Q4dUDCA8&uact=5&oq=sondh+web+creators&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzoHCCMQsAMQJzoKCAAQRxDWBBCwAzoKCAAQigUQsAMQQzoHCCMQigUQJzoLCAAQgAQQsQMQgwE6BQgAEIAEOgsIABCKBRCxAxCDAToRCC4QgwEQxwEQsQMQ0QMQgAQ6DQgAEIoFELEDEIMBEEM6BwgAEIoFEEM6EwguEIoFELEDEIMBEMcBENEDEEM6CggAEIAEEBQQhwI6BwguEIoFEEM6DQguEIoFEMcBENEDEEM6EAgAEIAEEBQQhwIQsQMQgwE6DgguEMcBELEDENEDEIAEOggILhCABBDlBDoLCC4QgAQQsQMQgwE6CAgAEIAEELEDOgsILhCABBDHARCvAToOCC4QgAQQsQMQxwEQ0QM6EQguEIAEELEDEIMBEMcBEK8BOgUILhCABDoHCAAQgAQQCjoHCAAQDRCABEoECEEYAFChBVjGHmCaIGgCcAB4AYABwAOIAbIikgEKMC4xLjEzLjAuM5gBAKABAcABAcgBCg&sclient=gws-wiz-serp" target="_blank" style={{textDecoration:"none"}}>
        
          <button className="secondary-button">
            Order Now <FiArrowRight />{" "}
          </button>
          </a>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container" id="About">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
          Sondh Web Creators
        </h1>
        <p className="primary-text">
         Sondh Web Creators established in 2011. Our company growing day by day. We are designing and developing website and apps.
        </p>
        <p className="primary-text">
        ਪੰਂਜਾਬ ਵਿੱਚ ਰਹਿਦੇ ਹੋ ਤਾਂ ਸੋਂਦ ਵੈਬ ਕਰੀਐਟਰ ਨੂੰ ਮੋਕਾ ਦਿਓ, ਪੰਜਾਬੀਆਂ ਨੂੰ ਮੋਕਾ ਦਿਓ । ਤੁਹਾਨੂੰ ਬਹੁਤ ਵਧੀਆਂ ਵੈਬ ਸਾਇਟ ਜਾਂ ਐਪ ਬਣਾ ਕਿ ਦਵਾਂਗੇ ।
        </p>
        <div className="about-buttons-container">
        <a href="https://www.google.com/search?q=sondh+web+creators&sxsrf=AB5stBgJ7YojIVWu9is1E62Cf8MGQRxErg%3A1688993918423&ei=fgCsZO6xGYz4-QaZv4PoDQ&ved=0ahUKEwju6KPUmISAAxUMfN4KHZnfAN0Q4dUDCA8&uact=5&oq=sondh+web+creators&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzoHCCMQsAMQJzoKCAAQRxDWBBCwAzoKCAAQigUQsAMQQzoHCCMQigUQJzoLCAAQgAQQsQMQgwE6BQgAEIAEOgsIABCKBRCxAxCDAToRCC4QgwEQxwEQsQMQ0QMQgAQ6DQgAEIoFELEDEIMBEEM6BwgAEIoFEEM6EwguEIoFELEDEIMBEMcBENEDEEM6CggAEIAEEBQQhwI6BwguEIoFEEM6DQguEIoFEMcBENEDEEM6EAgAEIAEEBQQhwIQsQMQgwE6DgguEMcBELEDENEDEIAEOggILhCABBDlBDoLCC4QgAQQsQMQgwE6CAgAEIAEELEDOgsILhCABBDHARCvAToOCC4QgAQQsQMQxwEQ0QM6EQguEIAEELEDEIMBEMcBEK8BOgUILhCABDoHCAAQgAQQCjoHCAAQDRCABEoECEEYAFChBVjGHmCaIGgCcAB4AYABwAOIAbIikgEKMC4xLjEzLjAuM5gBAKABAcABAcgBCg&sclient=gws-wiz-serp" target="_blank" style={{textDecoration:"none"}}>
          <button className="secondary-button">Learn More</button>
          </a>
          {/* <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default About;

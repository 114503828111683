import React from "react";
import ScrollToHashElement from "./HelpingComponents/ScrollToHashElement";
import Home from "./Home";
import About from "./About";
import Work from "./Work";
import Testimonial from "./Testimonial";
import Contact from "./Contact";
import Footer from "./Footer";
const HomePage = () => {
    return(
      <div className="App">
       <ScrollToHashElement />
       <Home />
        <About />
       <Work />
        <Testimonial />
       <Contact />
        <Footer />
        </div>
    )
  }

  export default HomePage